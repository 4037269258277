import { Button, Row, Col, Form as ReactForm, Alert, Fade, Label, Input } from "reactstrap";
import Section from "./Section";
import React, { useEffect, useRef, useState } from "react";
import ConfirmationModal from "./modals/ConfirmationModal";
import { DSP_SUBMISSION, SUCCESSFUL_ALERT_VARIANT, SUCCESSFUL_MESSAGE_SAVE_FORM, SUCCESSFUL_MESSAGE_SUBMIT_FORM, 
    FAILED_ALERT_VARIANT, FAILED_MESSAGE_API_UNAVAILABLE_SUBMIT_FORM, FAILED_MESSAGE_EXISTING_FORM } from "../AppConstants";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from "./dragndrop/SortableItem";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

function Form(props) {
    const tempFormJson = (props.form.sections) ? props.form : {
        ...props.form,
        sections: [],
        title: ""
    }
    const [formJson, setFormJson] = useState(tempFormJson);
    const [globalFieldList, setGlobalFieldList] = useState([]);
    const [formValidation, setFormValidation] = useState(props.formValidation);
    const formRef = useRef(null);

    const isValidated = props.isValidated;
    const setIsValidated = props.setIsValidated;

    useEffect(() => {
        let tempGlobalFieldList = [];
        if (formJson.sections) {
            formJson.sections.forEach((section) => {
                if (section.fields) { 
                    tempGlobalFieldList = [...tempGlobalFieldList, ...section.fields]
                }
            })
        }
        setGlobalFieldList(tempGlobalFieldList);
        props.onFormChange(formJson);
    }, 
    // eslint-disable-next-line
    [formJson]);

    //#region Form Button
    function addNewSectionOnClick() {
        const newSectionJson = {
            id: formJson.sections.length + 1
        };
        setFormJson({
            ...formJson,
            sections: [...formJson.sections, newSectionJson]
        })

        const newSectionValidation = {
            id: formValidation.sections.length + 1,
            fields: []
        }
        setFormValidation({
            ...formValidation, 
            sections: [...formValidation.sections, newSectionValidation]
        })
    }

    function deleteSection(section) {
        const newSectionArray = formJson.sections.filter(a => a.id !== section.id);
        const newValidSectionArray = formValidation.sections.filter(a => a.id !== section.id);
        setFormValidation({
            ...formValidation, 
            sections: newValidSectionArray
        })
        setFormJson({
            ...formJson,
            sections: newSectionArray
        })
    }

    function resetFormOnClick() {
        openResetConfirmationModal();
    }

    function resetFormOnConfirmationClick() {
        setFormValidation({
            title: null, 
            sections: []
        })
        setFormJson({
            ...formJson,
            sections: [],
            title: ""
        });
        setIsValidated(false);
        setShowResetConfirmationModal(false);
    }

    function checkIsFormValid() {
        return !!formValidation.title && Array.isArray(formValidation.sections)
        && formValidation.sections.every(section => {
            const isSectionTitleValid = !!section.title;
            const areFieldsdValid = Array.isArray(section.fields) && section.fields.every(field => !!field.inputType && !!field.label);
            const datagrids = Array.isArray(section.fields) ? section.fields.filter(field => field.datagridFields && field.datagridFields.fields && field.datagridFields.fields.length > 0) : [];
            const isDatagridPresent = datagrids.length > 0;
            const areDatagridValid = isDatagridPresent
                                    && datagrids.every(field => {
                                        if (field.datagridFields) {
                                            return Array.isArray(field.datagridFields.fields) && field.datagridFields.fields.every(field => !!field.inputType && !!field.label);
                                        } else
                                            return true;
                                    });

            return Array.isArray(section.fields) && section.fields.length > 0
                ? isSectionTitleValid && areFieldsdValid && (isDatagridPresent ? areDatagridValid : true)
                : isSectionTitleValid
        });
    }

    function saveFormOnClick(event) {
        const isformValid = checkIsFormValid();
        setIsValidated(true);
        if (!isformValid) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const downloadButton = document.createElement('a');
            const formJsonConverted = JSON.stringify(formJson);
            const file = new Blob([formJsonConverted], { type: 'application/json' });
            downloadButton.href = URL.createObjectURL(file);
            downloadButton.download = formJson.title.replaceAll(" ", "_") + ".json";
            document.body.appendChild(downloadButton);
            downloadButton.click();

            setShowAlert(true);
            setAlertMessage(SUCCESSFUL_MESSAGE_SAVE_FORM);
            setAlertVariant(SUCCESSFUL_ALERT_VARIANT);
        }
    }

    function submitFormOnClick(event) {
        const isformValid = checkIsFormValid();
        setIsValidated(true);
        if (!isformValid) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formJson)
            };
            fetch(DSP_SUBMISSION, requestOptions)
                .then(response => response.json())
                .then(json => handleSubmissionRequest(json))
                .catch(error => {
                    setShowAlert(true);
                    setAlertMessage(FAILED_MESSAGE_API_UNAVAILABLE_SUBMIT_FORM);
                    setAlertVariant(FAILED_ALERT_VARIANT);
                });
        }
    }

    function handleSubmissionRequest(json) {
        if (json && json.isSuccessStatusCode) {
            setShowAlert(true);
            setAlertMessage(SUCCESSFUL_MESSAGE_SUBMIT_FORM);
            setAlertVariant(SUCCESSFUL_ALERT_VARIANT);
        } else {
            console.log(json);
            setShowAlert(true);
            setAlertMessage(json.message);
            setAlertVariant(FAILED_ALERT_VARIANT);
        }
    }
    //#endregion
    
    function formNameOnChange(event) {
        const isFormTitleValid = !!event.target.value && event.target.value.trim().length > 0;
        setFormValidation({
            ...formValidation,
            title: isFormTitleValid
        });
        setFormJson({
            ...formJson,
            title: event.target.value
        })
    }
    
    // function testOnClick() {
    //     console.log(formJson);
    //     console.log(globalFieldList);    
    // }

    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    function closeResetConfirmationModal() {
        setShowResetConfirmationModal(false);
    }
    function openResetConfirmationModal() {
        setShowResetConfirmationModal(true)
    };

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    //#region Drag and Drop
    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    function handleDragEnd(event) {
        const {active, over} = event;
        
        if (active.id !== over.id) {
            const oldSectionIndex = formJson.sections.findIndex(section => section.id === active.id);
            const newSectionIndex = formJson.sections.findIndex(section => section.id === over.id);
            const newSections = arrayMove(formJson.sections, oldSectionIndex, newSectionIndex);
            setFormJson({
                ...formJson,
                sections: (newSections)
            });
        }
    }
    //#endregion

    return <ReactForm ref={formRef}>
        <Row xs="auto">
            <Col xs="auto">
                <Label className="middle-title">Form Title</Label>
            </Col>
            <Col md={3}>
                <Input placeholder="Please enter Form name" value={formJson.title} onChange={formNameOnChange} valid={isValidated && !!formValidation.title} invalid={isValidated && !formValidation.title}></Input>
            </Col>
            <Col>
                <Button color="primary" onClick={addNewSectionOnClick} outline>Add New Section</Button>
            </Col>
            {/* <Col>
                <Button variant="outline-primary" onClick={testOnClick}>Test</Button>
            </Col> */}
        </Row>
        <hr/>
        <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={formJson.sections.map(section => section.id)} strategy={verticalListSortingStrategy}>
                {formJson.sections.map((section) => {
                    const sectionValidation = formValidation.sections.find(s => s.id === section.id);
                    return <SortableItem className="section-parent-draggable" key={section.id} id={section.id}>
                        <Section section={section} sectionValidation={sectionValidation} isValidated={isValidated} onDelete={deleteSection} formJson={formJson} formValidation={formValidation} onSectionChange={setFormJson} onValidationChange={setFormValidation} globalFieldList={globalFieldList}></Section>
                        <hr/>
                    </SortableItem>
                })}
            </SortableContext>
        </DndContext>
        <Row className="mb-3">
            <Col xs="auto">
                <Button color="primary" onClick={saveFormOnClick} outline>Save Form</Button>
            </Col>
            <Col xs="auto">
                <Button color="primary" onClick={submitFormOnClick} outline>Submit Form</Button>
            </Col>
            <Col xs="auto">
                <Button color="primary" onClick={resetFormOnClick} outline>Reset Current Form</Button>
            </Col>
        </Row>

        <ConfirmationModal 
            show={showResetConfirmationModal}
            onModalClose={closeResetConfirmationModal}
            onModalUpdate={resetFormOnConfirmationClick}
        />

        <Row>
            <Col xs="auto">
                <Fade in={showAlert} timeout={500}
                    onEntered={() => {
                        setTimeout(() => {
                        setShowAlert(false)
                        }, 10000);
                    }}>
                    <Alert color={alertVariant} isOpen={showAlert} xs="auto" toggle={() => setShowAlert(false)}>
                        {alertMessage}
                    </Alert>
                </Fade>  
            </Col>
        </Row>
        
    </ReactForm>
}

export default Form;