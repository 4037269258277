import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
    ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_LIST,
    ADDITIONAL_APPLICATION_USER_ROLES_LIST,
    ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST,
    FORM_ENGINE_GENERATE_USER_TOKEN,
    FORM_ENGINE_RETRIEVE_EXCEL_TEMPLATE_URL,
    GET_FILE_BY_NAME_URL,
    PATH, FORMIO_URL_GET_ALL_FORMS, FORMIO_FORM_TYPE, FORMIO_URL
} from '../../../App/AppSettings';
import { fetchRequest, getFileRequest, getParams, isValidForm, postParams, navigateTo } from '../../../Shared/Actions';
import { AuthConsumer } from '../../../Shared/Authentication/AuthContext';
import { FIELD_SIZE } from '../../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../../Shared/Constants/LanguageKeys';
import { ActionButton, DataTable } from '../../../Shared/DataTable';
import { getCustomComponents } from '../../../Shared/DataTable/BaseTable.js';
import { AccordionContainer, FileUpload, SelectList, Text } from '../../../Shared/Forms';
import { toastError, toastSuccess } from '../../../Shared/Forms/Toaster.js';
import { AdditionalInformationModalConfirm, ModalConfirm } from '../../../Shared/Modal';
import { getLangKey } from '../../DisplayComponents/DisplayUtils';
import { SupportingDocumentModal } from '../AdditionalApplicationModal';
import { previewAdditionalForm } from './LicenceAppConfigUtils';
import utils from 'formiojs/utils';

const ADDITIONAL_APPICATION_FIELDS_VALUES = {
    WorkflowStateId: 0,
    WorkflowStateName: '',
    DisplayTitle: '',
    RequiredActions: [],
    UserRoleName: '',
    UserRoleGUID: [],
    AdditionalApplicationDocumentFile: '',
    FileName: '',
    FileKey: '',
    SupportingDocumentData: [],
    FormIOName: '',
    FormIOTranslationKey: []
};

export default function LicenceAppConfigAdditionalInformation(props) {
    const { t, i18n } = useTranslation();
    const addAdditionalApplicationFieldsValidation = Yup.object().shape({
        WorkflowStateId: Yup.number().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        DisplayTitle: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        RequiredActions: Yup.array().min(1, (ERROR.LABEL) + t(ERROR.REQUIRED)).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        UserRoleGUID: Yup.array().min(1, (ERROR.LABEL) + t(ERROR.REQUIRED)).required((ERROR.LABEL) + t(ERROR.REQUIRED)),
        AdditionalApplicationDocumentFile: Yup.string(),
        FormIOName: Yup.string().when('IsUsingFormIO', {
                    is: 'true',
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                }),
        FileName: Yup.string().when('IsUsingFormIO', {
                    is: 'false',
                    then: Yup.string().required((ERROR.LABEL) + t(ERROR.REQUIRED))
                })
    });   
    const { values, toggleSection, errors } = props.smartFormValues;
    const [additionalApplicationFieldsData, setAdditionalApplicationFieldsData] = useState([]);
    const [modalState, setModalState] = useState(null);
    const [selectedAdditionalApplicationFieldsError, setAdditionalApplicationFieldsError] = useState(false);
    const [associatedWorkflowStateList, setAssociatedWorkflowStateList] = useState([]);
    const [requiredActionsList, setRequiredActionsList] = useState([]);
    const [userRolesForFormViewList, setUserRolesForFormViewList] = useState([]);
    const [userRolesNoTrans, setUserRolesNoTrans] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [additionalApplicationFieldsValues, setAdditionalApplicationFieldsValues] = useState(ADDITIONAL_APPICATION_FIELDS_VALUES);
    const [additionalApplicationFieldsCount, setAdditionalApplicationFieldsCount] = useState(values.ApplicationOfficerData.AdditionalApplicationFieldsData.length);
    const [fileIndex, setFileIndex] = useState(-1);
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextForm, setNextForm] = useState({});
    const [previousForm, setPreviousForm] = useState({});
    const [nextDisabled, setNextDisabled] = useState(false);
    const [tempAdditionalApplicationFieldsData, setTempAdditionalApplicationFieldsData] = useState([]);
    // Retrieve list of forms from Form.IO
    const [formIOData, setFormIOData] = useState([]);

    const SECTION_NAMES = {
        ADDITIONAL_APPLICATION_FORMS: { title: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_ADDITIONALAPPLICATIONFORMS), status: true }
    };
    
    const ADD_ADDITIONAL_APPICATION_FIELDS_MODAL = {
        hasModal: true,
        name: 'AddAdditionalApplicationFields',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDADDITIONALAPPFIELDS)
    };
    
    const EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL = {
        hasModal: true,
        name: 'EditAdditionalApplicationFields',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITADDITIONALAPPFIELDS)
    };
    
    const DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL = {
        hasModal: true,
        name: 'DeleteAdditionalApplicationFields',
        modalHeader: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE),
        modalContent: t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD)
    };

    const getFileTemplate = async profile => {
        const userDetails = {
            'userName': `${profile.given_name} ${(profile.middle_name ? `${profile.middle_name} ` : '')}${profile.family_name}}`,
            'userEmail': profile.email,
            'userGuid': profile.userguid
        };
        const response = await fetchRequest(FORM_ENGINE_GENERATE_USER_TOKEN, postParams('', userDetails), false);

        if (response && response.success) {

            if (response.body.IsSuccess === true) {
                getFileRequest(FORM_ENGINE_RETRIEVE_EXCEL_TEMPLATE_URL, false, 'ExcelFileTemplate.xlsm', getParams());
                toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, response.body.Messages)));
            } else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, response.body.Messages)), response.body.Messages);
            }
        } else {
            toastError(t(ERROR.SERVER_UNREACHABLE));
        }
    };

    //Set relevant data from props
    useEffect(() => {
        setAdditionalApplicationFieldsData(values.ApplicationOfficerData.AdditionalApplicationFieldsData);
    }, [values.ApplicationOfficerData.AdditionalApplicationFieldsData]);

    //Fetch select list options
    useEffect(() => {
        if (values.ApplicationData.InternalWorkflowScheme) {
            // Retrieve User Role GUID and Name by using Scheme Code
            const fetchAdditionalApplicationUserRolesResponse = async schemeCode => {
                const response = await fetchRequest(`${ADDITIONAL_APPLICATION_USER_ROLES_LIST}schemeCode=${schemeCode}`, getParams(), false);
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const additionalApplicationUserRolesOptions = [];
                    const roles = [];
                    Data.forEach(element => {
                        additionalApplicationUserRolesOptions.push({ label: t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_KEY, element.UserRoleName)), value: element.UserRoleGUID });
                        roles.push({ label: element.UserRoleName, value: element.UserRoleGUID });
                    });
                    setUserRolesNoTrans(roles);
                    setUserRolesForFormViewList(additionalApplicationUserRolesOptions);
                }
            };

            // Retrieve Workflow State Id and Name by using Scheme Code
            const fetchAdditionalApplicationWorkflowResponse = async schemeCode => {
                const response = await fetchRequest(`${ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST}schemeCode=${schemeCode}`, getParams(), false);
                const { Data, IsSuccess } = response.body;
                if (response.success && IsSuccess) {
                    const additionalApplicationWorkflowSchemeOptions = [];
                    Data.forEach(data => {
                        additionalApplicationWorkflowSchemeOptions.push({ label: data.WorkflowStateName, value: data.WorkflowStateId });
                    });
                    setAssociatedWorkflowStateList(additionalApplicationWorkflowSchemeOptions);
                }
            };

            // React-Hook that is use to call the above 2 methods
            fetchAdditionalApplicationUserRolesResponse(values.ApplicationData.InternalWorkflowScheme);
            fetchAdditionalApplicationWorkflowResponse(values.ApplicationData.InternalWorkflowScheme);
        }
    },
        [values.ApplicationData.InternalWorkflowScheme, t]
    );

    const displayRequiredMessage = label => (
        `${label} ${t(ERROR.REQUIRED)}`
    );

    // Reset all the fields in additional form modal
    const resetAdditionalApplicationFieldsModal = () => {
        setAdditionalApplicationFieldsValues(ADDITIONAL_APPICATION_FIELDS_VALUES);
    };

    const toggleModal = modalName => {
        if (modalName === ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name || modalName === EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name) {
            resetAdditionalApplicationFieldsModal();
            setAdditionalApplicationFieldsError(false);
        }
        if (values.ApplicationOfficerData.AdditionalApplicationFieldsData.length - 1 >= 0) {
            setPreviousDisabled(false);
        }
        if (modalName === ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name) {
            setPreviousForm(values.ApplicationOfficerData.AdditionalApplicationFieldsData[values.ApplicationOfficerData.AdditionalApplicationFieldsData.length - 1]);
            setNextDisabled(true);
        }
        setModalState((modalState !== modalName) ? modalName : null);
    };

    // Modal add form, save and close current modal
    const addAdditionalApplicationFields = () => {
        if (isValidForm(addAdditionalApplicationFieldsValidation, additionalApplicationFieldsValues)) {
            setAdditionalApplicationFieldsError(false);
            const data = [...additionalApplicationFieldsData];
            data.push({ ...additionalApplicationFieldsValues, RowId: additionalApplicationFieldsCount });
            setAdditionalApplicationFieldsCount(additionalApplicationFieldsCount + 1);
            setAdditionalApplicationFieldsData(data);
            values.ApplicationOfficerData.AdditionalApplicationFieldsData = data;
            toggleModal(ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
        } else {
            setAdditionalApplicationFieldsError(true);
        }
    };

    // Modal add form, save the current form and reset the additional form fields to let the user enter the form again
    const addAdditionalApplicationFieldsOpenNewForm = () => {
        if (isValidForm(addAdditionalApplicationFieldsValidation, additionalApplicationFieldsValues)) {
            setAdditionalApplicationFieldsError(false);
            const data = [...additionalApplicationFieldsData];
            data.push({ ...additionalApplicationFieldsValues, RowId: additionalApplicationFieldsCount });
            setAdditionalApplicationFieldsCount(additionalApplicationFieldsCount + 1);
            setAdditionalApplicationFieldsData(data);
            values.ApplicationOfficerData.AdditionalApplicationFieldsData = data;
            resetAdditionalApplicationFieldsModal();
            if (data.length - 1 < 0) {
                setPreviousDisabled(true);
            } else {
                setPreviousDisabled(false);
                setPreviousForm(data[data.length - 1]);
            }
        } else {
            setAdditionalApplicationFieldsError(true);
        }
    };

    // Retrieve the correct additional information fields to be display on the 'edit additional information modal'
    const editAdditionalApplicationFields = data => {
        populateRequiredActions(data.WorkflowStateId);

        const index = values.ApplicationOfficerData.AdditionalApplicationFieldsData.findIndex(val =>
            val.RowId === data.RowId
        );

        // Set file field values.
        // Check if the data is from 'Create LAC' or 'Edit LAC'
        // If data is retrieved from 'Create LAC', then data will come from 'values.ApplicationOfficerData.AdditionalApplicationFieldsData'
        // If data is retrieved from 'Edit LAC' then data will come from 'values.AdditionalApplicationFileUploadSectionFiles'
        if (!values.ApplicationOfficerData.AdditionalApplicationFieldsData[index]['AdditionalApplicationDocumentFile']) {
            let fileIndex = values.AdditionalApplicationFileUploadSectionFiles.findIndex(val => val.key === data.FileKey);
            if (fileIndex < 0) {
                fileIndex = values.AdditionalApplicationFileUploadSectionFiles.findIndex(val => val.Filename === data.FileKey);
            }
            data.AdditionalApplicationDocumentFile = values.AdditionalApplicationFileUploadSectionFiles[fileIndex];
        } else {
            data.AdditionalApplicationDocumentFile = values.ApplicationOfficerData.AdditionalApplicationFieldsData[index]['AdditionalApplicationDocumentFile'];
        }

        // Get temp data index by workflow state Id
        let tempdata = [];
        const tempindex = additionalApplicationFieldsData.findIndex(val =>
            val.WorkflowStateId === additionalApplicationFieldsValues.WorkflowStateId
        );
        // Check if tempAdditionalApplicationFieldsData is already set, if is empty set the temp values using the original data
        if (tempAdditionalApplicationFieldsData.length > 0) {
            tempdata = [...tempAdditionalApplicationFieldsData];
        } else {
            tempdata = [...additionalApplicationFieldsData];
        }
        tempdata[tempindex] = additionalApplicationFieldsValues;

        // Under edit additional information modal check if there is any previous form
        // If user click on the first row edit, set previous button to disabled
        // Else set previous form data
        if (index === 0) {
            setPreviousDisabled(true);
            setNextDisabled(false);
        } else {
            setPreviousDisabled(false);
            setNextDisabled(false);
            setPreviousForm(tempdata[index - 1]);
        }

        // Under edit addtional information modal check if there us any next form
        // If there is next form, set next form data
        // Else if it will check all the workflow state id if is used up,
        // If it is used up then set the button as disabled.
        // Else not it will open a new 'Add additional information modal'.
        if (tempdata[index + 1]) {
            setNextForm(tempdata[index + 1]);
        } else {
            setNextDisabled(true);
        }

        setFileIndex(fileIndex);
        setSelectedIndex(index);
        setAdditionalApplicationFieldsValues(data);
        setTempAdditionalApplicationFieldsData(tempdata);
        setModalState(EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
    };

    const confirmEditAdditionalApplicationFields = () => {
        if (isValidForm(addAdditionalApplicationFieldsValidation, additionalApplicationFieldsValues)) {
            setAdditionalApplicationFieldsError(false);
            const data = [...tempAdditionalApplicationFieldsData];
            data[selectedIndex] = additionalApplicationFieldsValues;
            setAdditionalApplicationFieldsData(data);
            values.ApplicationOfficerData.AdditionalApplicationFieldsData = data;
            toggleModal(EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
            setSelectedIndex(null);
        } else {
            setAdditionalApplicationFieldsError(true);
        }
    };

    const deleteAdditionalApplicationFields = data => {
        toggleModal(DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
        const index = values.ApplicationOfficerData.AdditionalApplicationFieldsData.findIndex(val =>
            val.RowId === data.RowId
        );
        setSelectedIndex(index);
    };

    const confirmDeleteAdditionalApplicationFields = () => {
        const data = [...additionalApplicationFieldsData];
        data.splice(selectedIndex, 1);
        setAdditionalApplicationFieldsData(data);
        values.ApplicationOfficerData.AdditionalApplicationFieldsData = data;
        if (values.AdditionalApplicationFileUploadSectionFiles && values.AdditionalApplicationFileUploadSectionFiles.length > 0) {
            const fileArray = [...values.AdditionalApplicationFileUploadSectionFiles];
            fileArray.splice(selectedIndex, 1);
            values.AdditionalApplicationFileUploadSectionFiles = fileArray;
        }
        setSelectedIndex(null);
    };

    // Set Additional Application Fields Values
    const setAAFieldsValues = (name, value) => {
        setAdditionalApplicationFieldsValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const setValues = (name, value, optionsArray) => {
        const id = optionsArray.findIndex(o => o.value === value);
        const label = optionsArray[id] ? optionsArray[id].label : null;

        if (name === 'WorkflowStateName') {
            setAdditionalApplicationFieldsValues(prevValues => ({ ...prevValues, 'WorkflowStateId': value, 'WorkflowStateName': (label ? label : value) }));
        }
        if (name === 'UserRoleName') {
            const userRoles = [];
            value.length > 0 && value.forEach(v => {
                const userRoleIndex = userRolesNoTrans.findIndex(o => o.value === v);
                const userRoleLabel = userRolesNoTrans[userRoleIndex] ? userRolesNoTrans[userRoleIndex].label : null;
                userRoles.push(userRoleLabel);
            });
            setAdditionalApplicationFieldsValues(prevValues => ({ ...prevValues, 'UserRoleGUID': value, 'UserRoleName': (userRoles ? userRoles : value) }));
        }
    };

    const onCreateAssociatedWorkflowStateOption = (name, value) => {
        setValues(name, value, associatedWorkflowStateList);
        const associatedWorkflowStates = [...associatedWorkflowStateList];
        associatedWorkflowStates.push({ label: value, value });
        setAssociatedWorkflowStateList(associatedWorkflowStates);
    };

    const onCreateRequiredActionsOption = (name, value) => {
        setAAFieldsValues(name, value);
        const requiredActions = [...requiredActionsList];
        requiredActions.push({ label: value, value });
        setRequiredActionsList(requiredActions);
    };

    const onCreateUserRolesForFormViewOption = (name, value) => {
        setValues(name, value, userRolesForFormViewList);
        const userRolesForFormViews = [...userRolesForFormViewList];
        userRolesForFormViews.push({ label: value, value });
        setUserRolesForFormViewList(userRolesForFormViews);
    };

    const populateRequiredActions = async workflowStateId => {
        if (workflowStateId > 0) {
            const requestURL = `${ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_LIST}schemeCode=${values.ApplicationData.InternalWorkflowScheme}&workflowStateId=${workflowStateId}`;
            const response = await fetchRequest(requestURL, getParams(), false);
            const { Data, IsSuccess } = response.body;
            if (response.success && IsSuccess) {
                const additionalApplicationRequiredActionsOptions = [];
                Data.forEach(element => {
                    additionalApplicationRequiredActionsOptions.push({ label: element.RequiredActions, value: element.RequiredActions });
                });
                setRequiredActionsList(additionalApplicationRequiredActionsOptions);
            }
        }
    };

    const getAssociatedWorkflowStateOptions = () => {
        let updatedAssociatedWorkflowStateOptions;
        if (modalState === EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name) {
            updatedAssociatedWorkflowStateOptions = associatedWorkflowStateList.filter(currDocOption =>
                !additionalApplicationFieldsData.map(doc => doc.WorkflowStateId).includes(currDocOption.value)
                || currDocOption.value === additionalApplicationFieldsValues.WorkflowStateId);
        } else {
            updatedAssociatedWorkflowStateOptions = associatedWorkflowStateList.filter(currDocOption =>
                !additionalApplicationFieldsData.map(doc => doc.WorkflowStateId).includes(currDocOption.value));
        }
        return updatedAssociatedWorkflowStateOptions;
    };

    const onChangeModalField = (name, value) => {
        setAdditionalApplicationFieldsValues(prevValues => ({ ...prevValues, [name]: value, FileName: value.name, FileKey: value.key }));
    };

    const setRequiredActions = rowData => {
        const actionList = [];
        rowData.RequiredActions && rowData.RequiredActions.forEach((action, index) => {
            actionList.push(<p key={index}>{action}</p>);
        });
        return <div className="additional-form-table-cell">{actionList}</div>;
    };

    const setAdditionalApplicationUserRoles = rowData => {
        const roleList = [];
        rowData.UserRoleName && rowData.UserRoleName.forEach((role, index) => {
            roleList.push(<p key={index}>{t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_KEY, role))}</p>);
        });
        return <div className="additional-form-table-cell">{roleList}</div>;
    };

    const formIOFormOptions = (formIOData !== undefined) ? 
        formIOData.filter(form => form.type === FORMIO_FORM_TYPE).map(form => ({
            label: form.title,
            value: FORMIO_URL + form.path
        })) : [];

    const formIOChangeHandler = (value) => {
        const selectedForm = formIOData.filter(form => form.path === value.split('/').pop());
        if (selectedForm && selectedForm[0] && selectedForm[0].components) {
            const flattened = utils.flattenComponents(selectedForm[0].components, true);
            const flattenedArray = Object.entries(flattened);
            const translationValuesArray = flattenedArray.map(([key, value]) => ({
                TranslationValue: value.label ? value.label : value.title,
                TranslationKey: key.split('.').at(-1),
                LanguageKey : i18n.language
            }));
            additionalApplicationFieldsValues.FormIOTranslationKey = translationValuesArray;
        }
    }

    useEffect(() => {
        var request = new Request(FORMIO_URL_GET_ALL_FORMS, {
            method: 'GET',
            headers: new Headers({
                'Range': 'items=0-'
            })
        });
        fetch(request).then((response) => response.json())
        .then ((responseJson) => {
            setFormIOData(responseJson);
        })
    }, []);

    const renderAdditionalApplicationFieldsContent = () => {
        const getDocument = additionalApplicationFieldsValues.AdditionalApplicationDocumentFile ?
            additionalApplicationFieldsValues.AdditionalApplicationDocumentFile :
            additionalApplicationFieldsValues.FileName;
        const associatedWorkflowStateOptions = getAssociatedWorkflowStateOptions();
        return (
            <div>
                <SelectList
                    isClearable={false}
                    name="WorkflowStateName"
                    value={additionalApplicationFieldsValues.WorkflowStateId}
                    minLength={0}
                    maxLength={140}
                    options={associatedWorkflowStateOptions}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_ASSOCIATEDWORKFLOWSTATE)}
                    isMulti={false}
                    onChangeField={(name, valueArr) => {
                        setValues(name, valueArr, associatedWorkflowStateList);
                        populateRequiredActions(valueArr);
                    }}
                    onCreate={onCreateAssociatedWorkflowStateOption}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)}
                    error={
                        (!additionalApplicationFieldsValues.WorkflowStateId && selectedAdditionalApplicationFieldsError) ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE)) :
                            ''
                    }
                    required
                />
                <Text
                    name="DisplayTitle"
                    value={additionalApplicationFieldsValues.DisplayTitle}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DISPLAYTITLE)}
                    onChange={e => setAAFieldsValues(e.target.name, e.target.value)}
                    minLength={0}
                    maxLength={140}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DISPLAYTITLE)}
                    error={!additionalApplicationFieldsValues.DisplayTitle && selectedAdditionalApplicationFieldsError ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DISPLAYTITLE)) : ''}
                    required
                />
                <SelectList
                    isClearable={false}
                    name="RequiredActions"
                    value={additionalApplicationFieldsValues.RequiredActions}
                    minLength={0}
                    maxLength={140}
                    options={requiredActionsList}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_REQUIREDACTIONS)}
                    isMulti={true}
                    onChangeField={(name, valueArr) => {
                        setAAFieldsValues(name, valueArr);
                    }}
                    onCreate={onCreateRequiredActionsOption}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS)}
                    error={
                        (additionalApplicationFieldsValues.RequiredActions.length === 0 && selectedAdditionalApplicationFieldsError) ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS)) :
                            ''
                    }
                    required
                />
                <SelectList
                    isClearable={false}
                    name="UserRoleName"
                    value={additionalApplicationFieldsValues.UserRoleGUID}
                    minLength={0}
                    maxLength={140}
                    options={userRolesForFormViewList}
                    placeholder={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_USERROLESFORMVIEW)}
                    isMulti={true}
                    onChangeField={(name, valueArr) => {
                        setValues(name, valueArr, userRolesForFormViewList);
                    }}
                    onCreate={onCreateUserRolesForFormViewOption}
                    inputSize={FIELD_SIZE.LARGE}
                    label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_USERROLESFORMVIEW)}
                    error={
                        (additionalApplicationFieldsValues.UserRoleGUID.length === 0 && selectedAdditionalApplicationFieldsError) ?
                            displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_USERROLESFORMVIEW)) : ''
                    }
                    required
                />
                {values.ApplicationData.IsUsingFormIO === 'false' &&
                    <div>
                        <AuthConsumer>
                            {({ isAuthenticated, profile }) => ((profile && isAuthenticated) && (
                                <>
                                    <p className="additional-form-download-template-link">
                                        <span className='cursor-pointer' onClick={() => getFileTemplate(profile)}><b><u>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_DOWNLOADEXCELTEMPLATE)}</u></b></span>
                                    </p>
                                </>
                            ))}
                        </AuthConsumer>
                        <FileUpload
                            key={additionalApplicationFieldsValues.WorkflowStateId}
                            fileList={values.AdditionalApplicationFileUploadSectionFiles}
                            inputSize={FIELD_SIZE.LARGE}
                            name="AdditionalApplicationDocumentFile"
                            label={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_UPLOADFORMFIELDS)}
                            accept=".xlsm"
                            onChangeField={(name, value) => onChangeModalField(name, value)}
                            value={getDocument}
                            multipleFilesWithSameName={true}
                            error={(!additionalApplicationFieldsValues.AdditionalApplicationDocumentFile && selectedAdditionalApplicationFieldsError) ? displayRequiredMessage(t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FILE)) : ''}
                            multiple={false}
                            getFileURL={GET_FILE_BY_NAME_URL}
                            required
                        />
                    </div>
                }

                {values.ApplicationData.IsUsingFormIO === 'true' &&
                    <div>
                        <Row>
                            <p className="app-fields-upload">{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_CLICK)}
                                <span className='cursor-pointer' onClick={() => {navigateTo(PATH.CONFIG.FORM_GENERATOR)}}><b>&nbsp;{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_HERE)}&nbsp;</b></span>
                                {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_ACCESSFORMGENERATOR)}
                            </p>   
                        </Row>
                        <SelectList
                            name="FormIOName"
                            value={additionalApplicationFieldsValues.FormIOName}
                            placeholder="Select Form"
                            minLength={0}
                            maxLength={140}
                            inputSize={FIELD_SIZE.MEDIUM}
                            label="Select Form"
                            required
                            isClearable={true}
                            options={formIOFormOptions}
                            onChangeField={(name, value) => {
                                setAdditionalApplicationFieldsValues(prevValues => ({ ...prevValues, [name]: value }));
                                // get all fields in such form
                                formIOChangeHandler(value);
                            }}
                            isMulti={false}
                            error={errors.ApplicationData && errors.ApplicationData.FormIOName}
                        />
                    </div>   
                }
                
                <Col className="button-group-center">
                    <Button color="neutral" size="sm" type="button" onClick={() => previewAdditionalForm(t, additionalApplicationFieldsValues)}>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW)}</Button>
                </Col>
                <hr />
                <div className="additional-form-modal-supporting-document-heading">
                    <h5>{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSUPPORTINGDOCUMENTS)}&nbsp;
                        <span className="additional-form-modal-supporting-document-span">{t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_OPTIONAL)}</span>
                    </h5>
                </div>
                {renderAdditionalApplicationSupportingDocumentGrid()}
            </div>
        );
    };

    const renderAdditionalApplicationFieldsGrid = () => {
        const associatedWorkflowStateOptions = getAssociatedWorkflowStateOptions();
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Button
                            className="float-right"
                            color="neutral"
                            onClick={() => {
                                resetAdditionalApplicationFieldsModal();
                                toggleModal(ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
                            }}
                            disabled={associatedWorkflowStateOptions.length === 0 ? true : false}
                        >
                            {t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD)}
                        </Button>
                    </Col>
                </Row>
                <div className="smart-list">
                    <DataTable
                        pageSize="5"
                        minFilterChars='2'
                        data={additionalApplicationFieldsData}
                        noResultsMessage={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOADDITIONALAPPFORM)}
                        components={getCustomComponents()}
                        columns={
                            {
                                RunningNumber: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER), width: '5%' },
                                WorkflowState: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE), width: '15%', DBkey: 'WorkflowStateName' },
                                DisplayTitle: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DISPLAYTITLE), width: '20%', DBkey: 'DisplayTitle' },
                                RequiredActions: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_REQUIREDACTIONS), width: '15%', DBkey: 'RequiredActions', setContent: setRequiredActions },
                                UserRoleName: {
                                    title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_USERROLES),
                                    width: '15%',
                                    DBkey: 'UserRoleName',
                                    setContent: setAdditionalApplicationUserRoles
                                },
                                FileName: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_FILE), width: '20%', DBkey: 'FileName' },
                                DocumentFile: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_FILE), width: '20%', DBkey: 'AdditionalApplicationDocumentFile', visible: false },
                                Actions: { title:  t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION), width: '10%' }
                            }
                        }
                        renderActions={({ RowData }) => (
                            <React.Fragment>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT)} color="forward" onClick={() => editAdditionalApplicationFields(RowData)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </ActionButton>
                                <ActionButton tooltip={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE)} color="hazard" onClick={() => deleteAdditionalApplicationFields(RowData)}>
                                    <FontAwesomeIcon fixedWidth icon={faTrash} />
                                </ActionButton>
                            </React.Fragment>
                        )}
                    />
                    <ModalConfirm
                        isOpen={(modalState === DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        contentHeader={DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.modalHeader}
                        contentBody={DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.modalContent}
                        confirmText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM)}
                        confirmCallback={() => {
                            toggleModal(DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.name);
                            confirmDeleteAdditionalApplicationFields();
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(DELETE_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                    />
                    <AdditionalInformationModalConfirm
                        isOpen={(modalState === ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        contentHeader={ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.modalHeader}
                        // Previous Form Modal
                        previousFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS)}
                        previousForm={() => {
                            editAdditionalApplicationFields(previousForm);
                        }}
                        // Save & Close Modal
                        saveCloseText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDCLOSE)}
                        saveCloseCallback={() => {
                            addAdditionalApplicationFields();
                        }}
                        // Cancel Modal Without Saving
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(ADD_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        // Save & Continue Adding New Form
                        saveAddNewText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDADDNEWFORM)}
                        saveAddNewCallback={() => {
                            addAdditionalApplicationFieldsOpenNewForm();
                        }}
                        // Next Form Modal
                        nextFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT)}
                        nextForm={() => { }}
                        saveAddNewDisabled={associatedWorkflowStateOptions.length - 1 === 0 ? true : false}
                        previousDisabled={previousDisabled}
                        nextDisabled={nextDisabled}
                        contentBody={renderAdditionalApplicationFieldsContent()}
                    />
                    <AdditionalInformationModalConfirm
                        isOpen={(modalState === EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        contentHeader={EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.modalHeader}
                        // Previous Form Modal
                        previousFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS)}
                        previousForm={() => {
                            editAdditionalApplicationFields(previousForm);
                        }}
                        saveCloseText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDCLOSE)}
                        saveCloseCallback={() => {
                            confirmEditAdditionalApplicationFields();
                        }}
                        cancelText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL)}
                        cancelCallback={() => toggleModal(EDIT_ADDITIONAL_APPICATION_FIELDS_MODAL.name)}
                        // Next Form Modal
                        nextFormText={t(LANGUAGE_KEYS.BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT)}
                        nextForm={() => {
                            editAdditionalApplicationFields(nextForm);
                        }}
                        previousDisabled={previousDisabled}
                        nextDisabled={nextDisabled}
                        contentBody={renderAdditionalApplicationFieldsContent()}
                    />
                </div>
            </React.Fragment>
        );
    };

    const renderAdditionalApplicationSupportingDocumentGrid = () => {
        return (
            <SupportingDocumentModal
                values={additionalApplicationFieldsValues}
            />
        );
    };

    return (
        <React.Fragment>
            <Container className="full-width">
                <AccordionContainer
                    title={SECTION_NAMES.ADDITIONAL_APPLICATION_FORMS.title}
                    active={SECTION_NAMES.ADDITIONAL_APPLICATION_FORMS.status}
                    onClick={toggleSection}
                    isIndividual={true}
                    sections={SECTION_NAMES}
                >
                    {renderAdditionalApplicationFieldsGrid()}
                </AccordionContainer>
            </Container>
        </React.Fragment>
    );
}
